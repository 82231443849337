export const contentData = [
  //!City Fighter
  {
    category: ["mobile_games"],
    title: "City Fighter vs Street Gang",
    date: "  September  2021 - March 2023",
    description:
      "Casual side scroller game with 10M+ downloads in Play store and 242K Ratings in App store",
    detailed_description:
      "My involvement in the project began with our contract with Game Theory Games and i worked on new features and maintenance. \nI worked on refactoring the main state machine for player character behaviour. As new weapons were added to the game, state machine began to bloat and became mostly unmaintainable. \nI solved this problem by implementing a concurrent state machine design where player movement and weapon logic got handled in simultaneous state machines.\nI have also refactored the combo logic by encapsulating attacks into Command objects, adding them to a queue and checking for valid combos within a timeframe by using  a trie structure to lookup valid combos.\nCombo mechanic in this game required hits to be valid (hitting enemy colliders) and be in a specific sequence to enable another button to execute the combo. \nI have also worked on a total UI redesign phase where the game has a dark and light UI modes.\nAlso, i worked on daily rewards and subscription model implementations to increase user retention and revenue.\nI worked with the design team to implement and create new features for this game.",
    skills: "  C#, Unity, Photon Networking",
    genre: null,
    job_type: "  Feature development and Game maintenance in Unity",
    role: "  Gameplay and UI programming , Multiplayer prototyping, Game maintenance as a part of Game Theory Games",
    image:
      "https://play-lh.googleusercontent.com/-qVtOWsgH0ftF1FIJ09Ce13rFn1zaBA_WVxRRBHyuvrG7alWLFA-nldU402Qm-T_Kg",
    images: [
      "https://play-lh.googleusercontent.com/M6F1PrZck8wn8JXoxu3acj60uqz347UfUMZplOklUaFBJDUZeiSbPCvtSfPZXFpTz7Q=w1052-h592",
      "https://play-lh.googleusercontent.com/XojiPutZ3_a-NVVKaexEl7F6JXhVxz3yPCGa2C8DR-nqAVtAZ0Hz68FPjQSgg4gcdLc=w1052-h592",
      "https://play-lh.googleusercontent.com/xTqdlVbukk_rizWhmlKV6_LD-VQj5msirJX2EL7tgK6QbZZRA21I81gKX89pAbf0DH-G=w1052-h592",
      "https://play-lh.googleusercontent.com/7Uj82h_fiZ6S8eal0DDigRlLRyu4j2rH8ONu0EVc_6WSQcCsbErOO_XlEDEV-MMfCA=w1052-h592",
      "https://play-lh.googleusercontent.com/KlqFVhZVpdtxwXoxyyJosDlQJTVmPT1Y-hU29JcCzSnOxxvURDMaIqQF1VGqVyerxJQ=w1052-h592",
      "https://play-lh.googleusercontent.com/HQhgGr8HDZ73fd0H7uaNtlSeNnCkWQOEGBtIa-qjowDv4BLlplFUjQyQS-1wBM0QfU8=w1052-h592",
      "https://play-lh.googleusercontent.com/AGnK9YODX2gipKInPONTCGSGfFZVIW_B_VM6QrtLILy94ZP921-CujeVexAXNS_tMKo=w1052-h592",
      "https://play-lh.googleusercontent.com/hKwe9p6dQPMIQ7jF9T6uW-j_I5QDCwGKiJkT8kjMBEinyZUjiNkU6ZsC57ItUlNLwt8=w1052-h592",
    ],
    horizontalImages: true,
    doubleImage: true,
    videoUrl: "https://www.youtube.com/embed/5rl6OhGCbUg",
    webGLUrl: null,
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.soneraltincit.cityfightvsstreetgang&hl=en&gl=US",
    app_store_link:
      "https://apps.apple.com/us/app/city-fighter-vs-street-gang/id1380448783",
  },
  //!Forsaken Graves
  {
    category: ["pc_games"],
    title: "Forsaken Graves",
    date: "  May 2024 - Present",
    description:
      "Multiplayer co-op zombie survival game built with Netcode for GameObjects, VContainer, Animancer and HDRP pipeline",
    detailed_description:
      "Personal passion project for learning Networking, VContainer and HDRP. \nImplemented a custom Client Side Prediction algorithm on top of Netcode for Gameobjects infrastructure \nImplemented lobbies using Unity Lobbies API and transport using Relay. \nCreated a custom FPS controller compatible with Networking.",
    skills: "  C#, Unity, Netcode for GameObjects, Gameplay Programming, Animancer",
    genre: "CO-OP Zombie Survival",
    job_type: "  Feature development and Game maintenance in Unity",
    role: "  Gameplay and UI programming , Multiplayer prototyping, Game maintenance as a part of Game Theory Games",
    image:
      "https://imgur.com/xTcfduj.jpg",
    images: [
      "https://imgur.com/xTcfduj.jpg",
      "https://imgur.com/2V02lgc.jpg",
      "https://imgur.com/RcdyG1r.jpg",
      "https://imgur.com/CEmSZyQ.jpg",
    ],
    horizontalImages: true,
    doubleImage: true,
    videoUrl: null,
    webGLUrl:null,
    google_play_link: null,
    app_store_link: null,
    github_link: "https://github.com/canafarci/ForsakenGraves",
  },
  //!Kemerburgaz
  {
    category: ["architectural_designs"],
    title: "Kemerburgaz Villas",
    date: "  September 2020",
    location: "   Kemerburgaz // İstanbul",
    description: "Housing project in Kemerburgaz/İstanbul",
    detailed_description:
      "Façade, landscape and interior design work with 3D renders",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/140740152287837.631b2f94e7994.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/140740152287837.631b2f94e7994.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/e56a9f152287837.631b2f94e9508.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/dd025d152287837.631b2f94e8b7c.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/291b80152287837.631b2f94e67a1.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/ca7858152287837.631b2f94e70b1.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/860819152287837.631b2f94e82d2.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/69844f152287837.631b2f94e5e0f.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link:
      "https://www.behance.net/gallery/152287837/Kemerburgaz-Villas",
  },
  //!Word Bubbles
  {
    category: ["mobile_games"],
    title: "Word Bubbles",
    date: "  February 2023 (Version 1) September 2023 - Present (Version 2)",
    location: null,
    description: "Word Puzzle genre Casual mobile game built with StrangeIoC and Live Ops",
    detailed_description:
      "In this project, i worked on adding a new event system, implemantation of Adressables,\nPlay store and App store leaderboards, notification system using Unity package",
    skills: "  Unity 3D, Addressables, Live Ops, Playables",
    genre: "  Word Puzzle",
    role: "  Programming, Feature implementation, Maintenance, completed with the Teek Games team",
    job_type:
      "  Maintenance and feature implementation job with Teek Games",
    image:
      "https://play-lh.googleusercontent.com/3tD_KayznIx3njVtXDfTDmOHUbW881WjjEMumA95BR63Ta1qyR_p0EaaMP_rOPe2XqKr=w2560-h1440-rw",
    images: [
      "https://play-lh.googleusercontent.com/3tD_KayznIx3njVtXDfTDmOHUbW881WjjEMumA95BR63Ta1qyR_p0EaaMP_rOPe2XqKr=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/cZYs-g5l3RGC5-41Eqnoo5A964N6GMW_eFZrWh6CPtRLGiJcfh7wJzDD5LyS7qD88E-g=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/Ab6AfjUTdNGfKdFjPfup7HRex2oTCPdD-yRUUTP_vmNuV7TNgzWRB4WHGXtrGPEs6Q=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/DGPSnJNspAZBjOlYLv2jApxN29vouPJ88rW9g4M7ywxAeRkFdXpxypg3e_a0vLgoNw=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/paC98hE2QLVhgwUEdJUaywDTwW2_VZ2wFLNXpSdMc8-qF_bMwbU3dNj_HVcIzCs9F5Y=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/HmKMHS4XyaDqP9s_oJHFLUYFqzLbFy98vARuib4U2TyDGzVWdPkfU8CE-B2jFTcX5hIA=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/YXnHn6fNVabKwr_GRw4klwTbVG4c856FTA-chwGteLVlq7bJQyV9HdIp7WP8nBVTwds=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/UH4hs9FbRPady5smnrquPh7yqL-MBZy75Aq-UT5AxoRp_RuvHa8J_0L-PriVGsMlTTw=w2560-h1440-rw",
    ],
    videoUrl:null,
    webGLUrl: null,
    webgl_width: "540",
    webgl_height: "900",
    github_link:null,
    behance_link: null,
    horizontalImages: true,
    google_play_link: "https://play.google.com/store/apps/details?id=com.TeekGames.WordBubbles&pcampaignid=web_share",
    app_store_link: "https://apps.apple.com/us/app/word-bubbles-blast/id6449360210"
  },
  //!Hidden Order
  {
    category: ["mobile_games"],
    title: "Hidden Order",
    date: "  March 2024 - Present",
    location: null,
    description: "Hidden Object genre casual mobile game, using Addressables, Zenject and a custom Signal-Command binding",
    detailed_description:
      "I led this project's development, with UML class and sequence diagram documentation,\nImplemented a custom binding of Command objects to Zenject signals, inspired by StrangeIoC's MVCS system,\nImplemented CCD Addressables background loading system\nSupervised creation of a Level Creation tool to place and generate data for a vast amount of hidden objects in-game",
    skills: "  Unity 3D, Addressables, Live Ops, Playables",
    genre: "  Word Puzzle",
    role: "  Scrum Master, Lead Developer, completed with the Teek Games team",
    job_type:
      "  Project creation, development and maintenance, completed with Teek Games team",
    image:
      "https://play-lh.googleusercontent.com/uxz-IxfddCnxG7EMbAewB_sDNmqEeYwfzOMPSCYrifnRfm-LdvgTdYpn2cIR2EHHig=w2560-h1440-rw",
    images: [
      "https://play-lh.googleusercontent.com/9G4pYPJQTGm5JeNPLdMW5-OFwroJAj21bi3SNp5zkGcq0AoqhxNuMu_worFUykce9_Q=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/QOc2UqdhXgfaIiO0wP5xMUdZAggl_ZlU0b-X1fXdQ-LtysuyG-_oJ6KF9xYgA2QfdRo=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/wPUXuHA9YhRxYQnhF6Tv9KFRb-45-LkKB9dFMWvDBUyiyzkqY2QinjNl-3v6Z5IciJwX=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/2v_4nIYnSy3VcnLDj64ch0fGKTcdyXu5-BAQ55RitCXQ8B1PTCnFTeROXVceXT_I4-A=w2560-h1440-rw",
    ],
    videoUrl:null,
    webGLUrl: null,
    webgl_width: "540",
    webgl_height: "900",
    github_link:null,
    behance_link: null,
    horizontalImages: true,
    google_play_link: "https://play.google.com/store/apps/details?id=com.TeekGames.HiddenOrder&pcampaignid=web_share",
    app_store_link: "https://apps.apple.com/us/app/hidden-order/id6479645238"
  },
  //!NYT
  {
    category: ["mobile_games"],
    title: "My Yellow Taxi Co.",
    date: "  October 2022 (Version 1) April 2023 (Version 2)",
    location: null,
    description: "Idle-Arcade mobile game with a taxi theme",
    detailed_description:
      "My Yellow Taxi Co. is an exciting and fast-paced time-management game that challenges players to create and manage their own taxi station.\nI was tasked with developing the central game loop and visual mechanics, as well as contributing to the design of levels and overall gameplay.\nFor this game, i used Zenject Dependency Injection to manage dependencies and implemented a helper NPC AI using state machine to help player with managing drivers.\nIn this game, there were 4 types of vehicles and each of them could be broken in a possible 3 ways, so i used a factory to pseudo-randomly generate vehicles and used callbacks to change the state of the vehicles as player acted on them.\nThere were 6 different upgrades which player could purchase to change the state of the game, so in order to simplify the persistent saving system, i used commands to execute state changes. \nI used the same approach for NPC behaviours, which simplified patrolling, waiting, moving to car and following the player states.\nMy other responsibilities for this game includes integrating Adjust and GameAnalytics, Designing and implementing adaptive UI, implementing player controls, animating objects using DOTween and level design.\nThis game was tested with Ketchapp and Supersonic and i participated in game design process with their publishing managers.",
    skills: "  Unity 3D, C#",
    genre: "  Idle-Arcade",
    role: "  Programming, Gameplay Design, UI Design, completed with the Flowtart Studios team",
    job_type:
      "  Prototype project initially developed for Game Theory Games and later revised with Ketchapp games",
    image:
      "https://i.ibb.co/YtbQGwR/uc-export-view-id-1nl-YRdp-CHuj-Fo-PCl-Ad-IXg76-X5ray-TJBdv.jpg",
    images: [
      "https://i.imgur.com/2Ef131Y.gif",
      "https://i.imgur.com/SrCAg8z.gif",
      "https://i.imgur.com/yG8NcoM.gif",
      "https://i.imgur.com/9Uoj09O.gif",
      "https://i.imgur.com/g1Ndpgm.gif",
      "https://i.imgur.com/DtPgV6T.gif",
      "https://i.imgur.com/1ejNfY7.gif",
      "https://i.imgur.com/CBjTyHY.gif",
      "https://i.imgur.com/sLX9yKw.gif",
      "https://i.imgur.com/UeLAcBl.gif",
      "https://i.imgur.com/EBSJjPS.gif",
      "https://i.imgur.com/8Lj841u.gif",
    ],
    videoUrl: "https://www.youtube.com/embed/v0I0KCClqKY",
    webGLUrl: "https://itch.io/embed-upload/7922490?color=ededed",
    webgl_width: "540",
    webgl_height: "900",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.taxicompany",
    github_link: "https://github.com/canafarci/MyYellowTaxiCo",
    behance_link: null,
    horizontalImages: true,
  },
  //!Hollow Ocean Game
  {
    category: ["pc_games"],
    title: "Hollow Ocean Interactive",
    date: "  August 2021  ",
    description: "Interactive experience for creating pollution awareness",
    detailed_description:
      "As a part of UC San Diego's Art department professor Pinar Yoldas's Studiolab Yoldas, this interactive experience preceded the 2021 Architecture Biennale presentation. It showcases the human impact on the ocean by interacting with 6 layers of damage caused by the humans.\nI implemented separate controls for VR and joystick controlled versions of the game.\nMy responsibilities also included importing of a 3D scanned mesh with data gathered from coral reefs and managing performance while displaying the mesh.",
    skills: "  C#, Unity",
    genre: null,
    job_type: "  Development and maintenance",
    role: "  Gameplay and UI programming, game maintenance as a part of Studiolab Yoldas",
    image: "https://i.ibb.co/18qP221/Screenshot-2023-11-08-022226.jpg",
    horizontalImages: true,
    doubleImage: true,
    videoUrl: "https://www.youtube.com/embed/uSWx1r0df4A",
    webGLUrl: null,
  },
  //!Drunken Dungeon
  {
    category: ["pc_games"],
    title: "Drunken Dungeon",
    date: "  July 2022",
    location: null,
    description: "An entry to the GMTK Game Jam 2022",
    detailed_description:
      "Traverse through the dungeons with your un-trusty dice of elemental power! \nYou can only use 3 of your 4 powers, and the powers you can use and the buttons for those powers change depending on the roll of the dice. \nDefeat all of the enemies in the level to advance to the next level. \nControl using WASD keys, spacebar, and mouse clicks, with ranged actions aimed at the cursor. \nRed - Ranged Fireball \nBlue - Area Ice Attack \nBrown - Earth Shield \nWhite - Air Teleport\n\n This project was created in two days, and i implemented core loop and pseudorandom skill input mapping changes.",
    skills: "  Unity 3D, C#",
    genre: "  Roguelike",
    role: "  Programming, Gameplay Design, Level Design",
    job_type: "  Passion Project, completed with the Flowtart Studios team",
    image: "https://img.itch.zone/aW1nLzk1NDk3NDkucG5n/315x250%23c/iCFxeA.png",
    images: [
      "https://i.imgur.com/tosck7B.gif",
      "https://i.imgur.com/XMkhnyL.gif",
      "https://i.imgur.com/mFFqP1K.gif",
      "https://i.imgur.com/mlWL8mJ.gif",
    ],
    videoUrl: "https://www.youtube.com/embed/ESMAKEjqmtw", // Add this only if video exists
    webGLUrl: "https://itch.io/embed-upload/7921006?color=ededed", // Add this only if WebGL exists
    webgl_width: "850",
    webgl_height: "580",
    github_link: "https://github.com/canafarci/DrunkenWizard",
    behance_link: null,
    horizontalImages: true,
    landscapeImage: true,
  },
  //!Cooking Game
  {
    category: ["pc_games"],
    title: "Cooking Game",
    date: "  October 2023",
    location: null,
    description: "A project to experiment with Netcode Co-op",
    detailed_description:
      "A learning project inspired from games like PlateUp and Overcooked. Started with following a lecture and diverged at design and code structure.\nImplemented Netcode to create a multiplayer game, used lobbies and Relay transport infrastructure.\nImplemented game logic which included generating cooking recipes, checking for delivered foods and state transition of cookable items.\nImplemented Input system to be playable with keyboard/mouse and gamepad.\nImplemented remappable input bindings.",
    skills: "  Unity 3D, C#",
    genre: "  Multiplayer Co-op",
    role: "  Programming, Gameplay Design, Level Design",
    job_type: "  Learning project",
    image: "https://i.ibb.co/Jrr6SYj/frame-1.jpg",
    images: [
      "https://i.ibb.co/TbVqRWg/Part1.gif",
      "https://i.ibb.co/187dgyH/Part2.gif",
      "https://i.ibb.co/QpdchBn/Part3.gif",
      "https://i.ibb.co/tPb3pjV/Part4.gif",
    ],
    webGLUrl: "https://itch.io/embed-upload/9055584?color=ededed", // Add this only if WebGL exists
    webgl_width: "850",
    webgl_height: "580",
    github_link: "https://github.com/canafarci/CookingGame",
    behance_link: null,
    horizontalImages: true,
    landscapeImage: true,
  },
  //!Skate Rush
  {
    category: ["mobile_games"],
    title: "Skate Rush",
    date: "  December 2021",
    location: null,
    description: "IK runner with a skateboard theme",
    detailed_description:
      "The player takes on the role of a skateboarder executing extreme maneuvers. As the player nears an action zone, time decelerates, allowing the player to manipulate the skateboarder’s limbs to perform stunts.\nImplemeted a draggable IK limbs to move player character to target positions.\nCreated a instant replay system.\nCreated a spline based movement system with swerve mechanics.\nUsed shader graph to create dynamic materials. ",
    skills: "  Unity 3D, C#",
    genre: "  IK runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.imgur.com/G0oLAOE.jpg",
    images: [
      "https://i.imgur.com/YlbXGjF.gif",
      "https://i.imgur.com/A7AITP3.gif",
      "https://i.imgur.com/xGv29Y9.gif",
      "https://i.imgur.com/4j8UfLh.gif",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7984512?color=333333",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/SkateDancer",
    behance_link: null,
  },
  //!Wonder
  {
    category: ["mobile_games"],
    title: "Wonder Builders",
    date: "  January 2023",
    location: null,
    description: "Idle clicker with a wonder building theme",
    detailed_description:
      "Players have the ability to optimize worker speed and quantity, combining them to expedite the construction of wonders.\nIn this prototype, players can build both the Eiffel Tower and the Giza Pyramid.\nI was tasked with developing the central game loop and visual mechanics, as well as contributing to the design of levels and overall gameplay.\nI implemented AI behaviors using a state machine and a dynamic NavMesh, enhancing the game's interactivity and realism.\nI created an upgrade system and designed a system which listens for player input to create a interactive idle game.\nI designed and implemented a reactive UI for the game.\nI established systems for game state management and data persistence.\nI managed the overall game flow, creating a coherent narrative and gameplay balance. ",
    skills: "  Unity 3D, C#",
    genre: "  Idle Clicker",
    role: "  Programming, Gameplay and Architectural design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.ibb.co/PDbL62r/frame-4.jpg",
    images: [
      "https://i.imgur.com/MDvuHe5.gif",
      "https://i.imgur.com/N0m6yBZ.gif",
      "https://i.imgur.com/DMuRgKK.gif",
      "https://i.imgur.com/v3D64Wa.gif",
    ],
    horizontalImages: true,
    videoUrl: "https://www.youtube.com/embed/lUnxmFhP6J0",
    webGLUrl: "https://itch.io/embed-upload/7933036?color=ededed",
    webgl_width: "500",
    webgl_height: "850",
    github_link: "https://github.com/canafarci/WonderBuilder",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.wondercraft",
    behance_link: null,
  },
  //!Obstacle Runner
  {
    category: ["mobile_games"],
    title: "Parkour Race Master",
    date: "  October 2023",
    location: null,
    description: "Obstacle dodge race game",
    detailed_description:
      "This prototype was inspired from Fun Race 3D by Azur Games.\nI was tasked with developing the central game loop and visual mechanics, as well as contributing to the design of levels and overall gameplay.\nUsed Zenject Dependecy injection to limit use of MonoBehaviours as much as possible for performace concerns with memory pools and prefab installer subcontainers.\nImplemented two compute shaders, one for painting an end game painting board with changeable colors and brush size, another for calculating total painted area in order to increase performance.\nCreated an AI state machine which responds to obstacles and acts accordingly.\nImplemented a 2D directional animation blend tree to respond to omnidirectional movement.\nUsed DOTween for obstacle movements and UI animations.\nCreated a physics based controller in order to respond to physics based obstacles.",
    skills: "  Unity 3D, C#",
    genre: "  Idle-Arcade",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.imgur.com/RxoMmPE.jpg",
    images: [
      "https://i.imgur.com/dWRHmVq.gif",
      "https://i.imgur.com/bhnBrkl.gif",
      "https://i.imgur.com/s64pAxo.gif",
      "https://i.imgur.com/IJX8H7Q.gif",
    ],
    horizontalImages: true,
    github_link: "https://github.com/canafarci/RunnerTest",
    behance_link: null,
  },
  //!House Flipper 3D
  {
    category: ["mobile_games"],
    title: "House Flipper 3D",
    date: "  March 2022",
    location: null,
    description: "House repair and renovation simulator",
    detailed_description:
      "Drawing inspiration from beloved house renovation reality programs, players begin by purchasing dilapidated houses. They undertake the challenge of restoring these homes, acquiring new furniture, assembling and creatively decorating interiors, and ultimately selling the rejuvenated properties for a lucrative profit.Programmed a texture painting system and a drag and drop system with raycasts.\nImplemented Unity analytics for tracking user behaviour and churn profile and implemented interstitial ads via Ironsource SDK.\nCreated tetris-like puzzle, vehicle control system and real-time voronoi destruction.",
    skills: "  Unity 3D, C#",
    genre: "  Simulator",
    role: "  Programming, Gameplay and Architectural design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.ibb.co/dfkPY1S/frame-6.jpg",
    images: [
      "https://i.imgur.com/lihhpEj.gif",
      "https://i.imgur.com/6SaDT9v.gif",
      "https://i.imgur.com/GryHlH9.gif",
      "https://i.imgur.com/GqnZ0jw.gif",
      "https://i.imgur.com/QdPeqoa.gif",
      "https://i.imgur.com/hpGvK5y.gif",
      "https://i.imgur.com/Ds7KQqc.gif",
      "https://i.imgur.com/Doahxp2.gif",
    ],

    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7937642?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/HouseFlipper",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.houseflipper",
    behance_link: null,
  },
  //!RadianceGAN
  {
    category: ["academic_work"],
    title: "Indoor Illumination Calculation AI",
    skills: "  Tensorflow, Python, Rhinoceros3D, Grasshopper, Honeybee",
    date: "  2020",
    location: "   Ankara // Turkey",
    description:
      "Generative Adversarial Network AI for fast prediction of indoor illumination ",
    detailed_description:
      "This initiative employs TensorFlow and generative AI methodologies to forecast the indoor lighting conditions of a conceptual plan sketch by examining color-coded architectural diagrams associated with daylighting simulation outcomes. \n\nBefore joining the EU-sponsored research program on global warming, which concentrated on urban heat islands and eventually became my thesis subject, I devised this AI model as a potential thesis theme.",
    genre: null,
    job_type: "  Study Collection",
    image:
      "https://i.ibb.co/V2yyhkq/uc-export-view-id-1-XBy-Hvd4-GHFVqf-Jmn0s-A29-GA1r4rfm-Bjl.png",
    pdf_link:
      "https://drive.google.com/file/d/1O6e-eDvBIPborOOtz04gcg8rMmiIt5CD/preview",
    github_link: "https://github.com/canafarci/MARCH_Repo",
  },
  //!Train
  {
    category: ["mobile_games"],
    title: "My Train Station!",
    date: "  November 2022",
    location: null,
    description: "Train station management Idle-Arcade",
    detailed_description:
      "In this game, players step into the role of a train station manager. \nTheir responsibilities include welcoming new passengers to the train station, providing luggage storage services if needed, and ensuring passengers board the train promptly.\nI was tasked with developing the central game loop and visual mechanics, as well as contributing to the design of levels and overall gameplay.\nI have implemented a object pool to optimize the memory for money objects and NPCs. I used a state machine to design an AI for a NPC which can help the player.\nOther features which i implemented in this game include Stacking mechanics, Analytics, persistent saving system and an unlocking system for player progress.\nThis game was tested with Supersonic and Game Theory Games and i participated in game design process with their publishing managers.",
    skills: "  Unity 3D, C#",
    genre: "  Idle-Arcade",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://img.itch.zone/aW1hZ2UvMjEwNjExMi8xMjM5ODIzNi5qcGc=/794x1000/5IUT%2B1.jpg",
    images: [
      "https://i.imgur.com/Ec1wfYv.gif",
      "https://i.imgur.com/MozRB1b.gif",
      "https://i.imgur.com/fXhbw7q.gif",
      "https://i.imgur.com/gmX3mVI.gif",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/8061466?color=333333",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/TrainStation",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.mytrainidlearcade",
    behance_link: null,
  },
  //!Swing
  {
    category: ["mobile_games"],
    title: "Swing Ride Idle ASMR",
    date: "  January 2023",
    location: null,
    description: "Idle clicker with a theme park setting",
    detailed_description:
      "Player operates a swing ride and generates income from the customers who ride it.\nPlayer has the option to purchase new seats, enhance the current ones, and acquire a new wheel to boost their profits even further.\nImplemented a rigidbody joint system which increases its rotational speed when clicked on the screen.\nCreated an upgrade system for the game.\nI was tasked with developing the central game loop and visual mechanics, as well as contributing to the design of levels and overall gameplay.",
    skills: "  Unity 3D, C#",
    genre: "  Idle Clicker",
    role: "  Programming, Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.imgur.com/3FuURRM.jpg",
    images: [
      "https://i.imgur.com/twZQ5f3.gif",
      "https://i.imgur.com/NJ29ME9.gif",
      "https://i.imgur.com/hf06U6T.gif",
      "https://i.imgur.com/t0fBiPP.gif",
    ],
    horizontalImages: true,
    videoUrl: "https://www.youtube.com/embed/7GpVk9cp-XA",
    webGLUrl: "https://itch.io/embed-upload/7936708?color=ededed",
    webgl_width: "500",
    webgl_height: "850",
    github_link: "https://github.com/canafarci/SwingClicker",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.swingclicker",
    behance_link: null,
  },
  //!Döşemealtı Villa
  {
    category: ["architectural_designs"],
    title: "Döşemealtı Villa",
    date: "  March 2021",
    location: "   Döşemealtı  // Antalya",
    description:
      "Interior, façade and landscape design work for a residential unit",
    detailed_description: "",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/5dc3f6152285065.631b255eb956a.png",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/5dc3f6152285065.631b255eb956a.png",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/6088d5152285065.631b255ebb879.png",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/242493152285065.631b255ebc921.png",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/9cd4d0152285065.631b255eb9d30.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/b7e67c152285065.631b255eba5d7.png",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/c3fe3f152285065.631b255ebc3ca.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2864fc152285065.631b255ebb312.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/7b8933152285065.631b255ebbe3e.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/af250c152285065.631b255ebacb8.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152285065/Doesemealt-Villa",
  },
  //!Bowling
  {
    category: ["mobile_games"],
    title: "Bowling Stack 3D",
    date: "  April 2022",
    location: null,
    description: "Forward stack runner with a bowling theme",
    detailed_description:
      "In the game, the player strategically places bowling balls to hit pins and accumulate points, all while avoiding various obstacles. Once the finish line is reached, the bowling balls combine to form a larger ball. The player then attempts to knock down as many stacked pins as possible with this larger ball.\nImplemented voronoi destruction, spline generation, spline following and a curved world shader.\nI was tasked with developing the central game loop and visual mechanics, as well as contributing to the design of levels and overall gameplay.",
    skills: "  Unity 3D, C#",
    genre: "  Forward Stack Runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.imgur.com/mivUiEl.jpg",
    images: [
      "https://i.imgur.com/Bch0bKr.gif",
      "https://i.imgur.com/DO2TwNO.gif",
      "https://i.imgur.com/OZBs32u.gif",
      "https://i.imgur.com/KvFJypm.gif",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7965305?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/BowlingStack",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.bowlingstack",
    behance_link: null,
  },
  //!Turtle
  {
    category: ["other_work"],
    title: "Turtlerz NFT Collection",
    date: "  March  2022",
    description:
      "Pseudo-random generation and Photoshop combination of an NFT project",
    detailed_description:
      "In the project, my role involved the creation of pseudo-random generation scripts for Adobe Photoshop. These scripts were designed to merge layers based on specific rarities, as dictated by the design document.\nIn addition to this, I was also tasked with the generation of metadata for the blockchain network.",
    skills: "  Javascript, Python, Photoshop",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/0f944b152292647.631b4067acc82.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/0f944b152292647.631b4067acc82.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/409799152292647.631b4067b1ef6.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/99db39152292647.631b4067b47c1.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/bfb2ba152292647.631b4067afa63.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/3a7009152292647.631b4067ad6e6.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/7f60a3152292647.631b4067b1642.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/3e7bc9152292647.631b4067ae295.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/6ddf34152292647.631b4067b27fd.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/c95f2d152292647.631b4067b544d.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/a59b26152292647.631b4067b334d.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/3c6866152292647.631b4067b0370.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/0cc113152292647.631b4067af0c3.jpg",
    ],
    horizontalImages: true,
    videoUrl: null,
    webGLUrl: null,
    github_link:
      "https://github.com/canafarci/TurtleNFTGenerator/blob/master/turtleGeneratorl.jsx",
    behance_link:
      "https://www.behance.net/gallery/152292647/Turtlerz-NFT-Collection",
  },
  //!Popcorn
  {
    category: ["mobile_games"],
    title: "Pop-it Popcorn ASMR",
    date: "  November 2022",
    location: null,
    description: "Popcorn Themed Idle Clicker",
    detailed_description:
      "Implemented a memory pool and used Flyweight pattern to optimize performance.",
    skills: "  Unity 3D, C#",
    genre: "  Idle Clicker",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image: "https://i.imgur.com/BwQH1Oh.jpg",
    images: [
      "https://i.imgur.com/sTuLBn0.gif",
      "https://i.imgur.com/9IBg54r.gif",
      "https://i.imgur.com/aPUQvFN.gif",
      "https://i.imgur.com/a7EJRaz.gif",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7939293?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/PopcornClicker",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.popcornclicker",
    behance_link: null,
  },
  //!Muratpaşa Housing
  {
    category: ["architectural_designs"],
    title: "Muratpaşa Housing",
    date: "  October 2023",
    location: "   Muratpaşa  // Antalya",
    description:
      "Interior, façade and landscape design work for a residential unit",
    detailed_description: "",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/a9f30d183247119.653be844dc0ab.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/a9f30d183247119.653be844dc0ab.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8a2425183247119.653be844de44a.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/763c56183247119.653be844df64a.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/b28a90183247119.653be844e96fc.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/1308f0183247119.653be844e5ec1.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152285065/Doesemealt-Villa",
  },
  //!Cupcake
  {
    category: ["mobile_games"],
    title: "Cupcake Stack",
    date: "  May 2022",
    location: null,
    description: "Forward stack runner with a cake theme",
    detailed_description:
      "The player is presented with cupcake molds on a moving platform. They collect these molds, fill them with dough, and bake them. After baking, they add sprinkles and filling to the cupcakes. The final step involves selling these cupcakes for a profit.",
    skills: "  Unity 3D, C#",
    genre: "  Forward stack runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://play-lh.googleusercontent.com/BMkVuEds9QMRHkncC8cELNi8Abkcs7dWS2_jOl--4yHdFeWy5xH1478OOVHVP6pui6U=w240-h480-rw",
    images: [
      "https://play-lh.googleusercontent.com/qVASqGYzDn8XZxofmfNlIu8rxvALB4c3ff8HaWa0ZrFFfgS421AO37d8HBvsT-PYa4Q=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/a8Qa-9lEm1uprqB3xWcBupnDbI2XaiUfANeGhSn8se_MilHFEGOXNfhSRQdLAaRc0g=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/5mlxRmDC27c11G0JwwkYbDNAZ_sHje6Soon2ChqMcKts0aQKRoFoGLemzfwGz3zVnUE=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/MFeL7EDP1MDEyB-GVESp_1DLMm0UIsDJyO3dSggJWtC-8kHlwogKZpoqwd1y5atXmQ=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/J-oysuOWZ1KzQ2VMr5yffDw4s--1uXf3ntuPR085QZjNzaqyPPEZfonll-wlL9a0BDM=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/w4O0z6F7BTLBVYjgN2cRGFmr5GN3QNrrNYgDwATJJ0tOVrTrIrcuiwD4Neun6Ktzmmc=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/LRpiJzZHy6IckbTyzfmg881pIT-bTdw8ncceA1tafPWW_ybc8H_oZePvOFUAjwEhbho=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/dLPq3hK31uiEpJAelTbmK_nv-hesNrWp-XPKSu2HHaQa8-OUSZ0XRZ89qab-iTNT9bci=w2560-h1440-rw",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7963906?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/CupcakeStack",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.cupcakestack",
    behance_link: null,
  },
  //!Tez
  {
    category: ["academic_work"],
    title: "Global Sensitivity Analysis for Urban Heat Island Effect",
    date: "August 2022",
    location: "   Ankara // Turkey",
    description: "Thesis written for MARCH degree",
    detailed_description:
      "As part of a European Union research programme with my university (BAP), my thesis focused on exploring the detrimental consequences of Urban Heat Islands (UHI) caused by rapid urbanization and inadequate government policies. UHI exacerbates resource consumption, environmental footprint, and poses risks to human health and comfort. I emphasized the need for new design and adaptation strategies in urban settlements to reduce energy consumption and environmental impact.\n\nTo investigate the thermal conditions under UHI effects, I utilized the Urban Weather Generator (UWG), a simulation package written in Python. This powerful tool allowed me to model and analyze the impact of various parameters on UHI intensity. The simulation incorporated geometric data created using Rhinoceros3D, a 3D modeling software, to accurately represent the urban environment.\n\nIn my research, I employed sensitivity analysis, a valuable method to measure the impact of input variables on the output. Sensitivity analysis helps identify the most influential parameters and understand their contributions to the overall UHI effect. It provides valuable insights for modeling, decision-making, and developing effective mitigation strategies.\n\nThrough global sensitivity analysis, I assessed UHI increase in a residential district in Ankara, Turkey, and identified the parameters that significantly contribute to UHI effects. By conducting simulations during average summer and winter weeks, I also explored the seasonal impact on UHI.\n\nThe findings of my thesis highlight the urgent need for sustainable urban development and offer valuable insights for mitigating the adverse effects of UHI.\n\nYou can also explore the source code of the project from the link on the upper right.",
    skills: "  Python, Rhinoceros3D, Grasshopper",
    genre: null,
    job_type: "  Master's Thesis",
    image: "https://i.ibb.co/b7LGznr/Screenshot-2023-06-09-at-20-41-36.png",
    github_link: "https://github.com/canafarci/Bap_Project",
    pdf_link:
      "https://drive.google.com/file/d/1rjUUK0w3BS7LreUYc7eU5_TxjX2J76oN/preview",
  },
  //!Hollow Ocean Video
  {
    category: ["other_work"],
    title: "AI Generated Videos for 2021 Venice Biennale",
    date: "  September  2021",
    description: "Aimed for creating environmental awareness",
    detailed_description:
      "Hollow Ocean is an art installation designed to offer a peek into the potential future transformations of marine environments.\n\nIn this project, the ocean is divided into six chapters, each representing a different aspect of death. The first chapter, Plastic Ocean, explores the invasion of aquatic life by man-made synthetic polymers. These plastics enter the food chain as both prey and predator, causing widespread death. The second chapter, Dark Ocean, delves into the darkest depths of the ocean floor where humans are drilling for petroleum. The third chapter, Phantom Ocean, reveals the devastating impact of overfishing, which has wiped out 90% of top predators in a relentless pursuit of seafood. The fourth chapter, Acid Ocean, examines the intricate and delicately balanced chemistry of the ocean, a salty womb that nurtures a plethora of creatures. However, even a slight increase in acidity can result in the loss of billions of lives. The fifth chapter, Stifled Ocean, portrays how both land and sea have been altered by human activity, with changes in temperature, salinity, and acidity stifling marine life. The final chapter, Hollow Ocean, is dedicated to the issue of global warming.",
    skills: "  Tensorflow, Python",
    genre: null,
    job_type: "  Freelance Project",
    role: "  Creating environmental awareness videos using Generative Adversarial Networks.",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4845f6152299431.631b57353de95.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4845f6152299431.631b57353de95.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/5d925f152299431.631b57353ee46.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/c5c107152299431.631b57353e623.jpg",
    ],
    horizontalImages: true,
    videos: [
      "https://www-ccv.adobe.io/v1/player/ccv/Lo-RhPkirgZ/embed?api_key=behance1&bgcolor=%23191919",
      "https://www-ccv.adobe.io/v1/player/ccv/TddXHDozVJq/embed?api_key=behance1&bgcolor=%23191919",
      "https://www-ccv.adobe.io/v1/player/ccv/UBPBNsGEC5U/embed?api_key=behance1&bgcolor=%23191919",
    ],
    webGLUrl: null,
    behance_link:
      "https://www.behance.net/gallery/152299431/Hollow-Ocean-2048-for-Venice-Biennale",
  },
  //!Cupid Run
  {
    category: ["mobile_games"],
    title: "Love or Hate Run",
    date: "  January 2022",
    location: null,
    description: "Narrative runner with a matchmaking theme",
    detailed_description:
      "In this game, the player steps into the shoes of Cupid, the arbiter of a couple’s fate. The player has the power to shape the couple’s relationship, either nurturing their love or instigating conflict leading to a breakup. The choices made by the player influence Cupid’s transformation into either an angel or a demon, reflecting the nature of their decisions.",
    skills: "  Unity 3D, C#",
    genre: "  Narrative runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://lh3.googleusercontent.com/l-mmiYgiLfbKbqUrhQEETvQd-m3eX_-NF83WU9fgmYi0F844yqGxP8qS-G9B8fAesQ",
    images: [
      "https://lh3.googleusercontent.com/nReme0Wo8wgHDBaNNoT7HxJIm-Uf-lt9Hj7bCsNQLn4RVR4wj1n0cEorEtCC4ut_Qg",
      "https://lh3.googleusercontent.com/PW4JDhr0q76W4XI76kyqiFvMtulA07J3VSNR0Dik5mpUnc2A4uSj7tONLm6g7IXqLUY",
      "https://lh3.googleusercontent.com/x7VdPbSkqd6eE2RIAKe46QXFiwjdpepZFguv73X_EVTvW8rL1MDpWAAiC6q_Z8jkI59v",
      "https://lh3.googleusercontent.com/YWcdc0HrqXUv3fpxGFu1ZgMWNJQrVWJ6z-BT5jj4r9Hd847apBY-2yokEGFl3iQ1DQ",
      "https://lh3.googleusercontent.com/mTENFjLtQjJZK2Vd-B7LWYC5NaCLLxIpC-d6PvLs6zEJjXrwJIZWh-T1GRTjOOAkGK0",
      "https://lh3.googleusercontent.com/jsSu1MjRERRGsOeBVhykiL7th2jqQzZ9ZQ1riR5oUob-3G7RlQAKxbhOKdI8hi_-aHg",
      "https://lh3.googleusercontent.com/wlXG5cMDOXkNuVhJNvqk7QeXHQ1ydCCOTwRw_FpvEnEfyFar5DroU3Y3Iajz8HWFo0U",
      "https://lh3.googleusercontent.com/-wcQHJDkunMOVSNRT7hkkLeOlfY16miS3SFiw7kTy0nE4KFicO5E8tZvxoeQv8jA25-i",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7983581?color=333333",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/CupidRun",
    behance_link: null,
  },
  //!License design works
  {
    category: ["academic_work"],
    title: "Bachelor's Degree Design Works",
    date: "  2014 - 2019",
    location: "   Ankara // Turkey",
    description: "Selected design works from my Bachelor's studies",
    detailed_description:
      "This collection includes works for my studio and architectural history classes",
    skills: "  Python, Rhinoceros3D, Grasshopper, Revit, Autocad",
    genre: null,
    job_type: "  Study Collection",
    image:
      "https://i.ibb.co/ScHYVyw/uc-export-view-id-1-Wv-Sml-Heced-D5-QXGObuqo-ZZx1-DJ-9-HSAg.png",
    pdf_link:
      "https://drive.google.com/file/d/13wVPfTiS3bp1vrDdM1VVwg5uqLM85wWr/preview",
  },
  //!License AH works
  {
    category: ["academic_work"],
    title: "Architectural History Researches",
    date: "  2015 - 2019",
    location: "   Ankara // Turkey",
    description: "Selected Architectural History researches from my studies",
    detailed_description:
      "This collection comprises architectural analysis papers on the Aachen Cathedral in Germany and the Santiago del Compostela in Spain, as well as a research paper titled ‘Evolution of the State Mechanism and Transformation from City Temple to Palace Temple in Ancient Mesopotamia’.\n\nThe first two works were part of a Bachelor’s degree program, while the latter was a component of a Master’s course in architectural history. The research paper delves into how cosmological patterns have been used as design inspirations in medieval architecture.",
    genre: null,
    job_type: "  Study Collection",
    image:
      "https://i.ibb.co/B6XTR83/uc-export-view-id-1s-IFKA6x-I2b-ODoyx5-EVJIpp0-J4dmq-F8-X.png",
    pdf_link:
      "https://drive.google.com/file/d/1GKva-cFVQjsJoc37s1BdD-fOtOpv3i84/preview",
  },
  //!Soccer Mania
  {
    category: ["mobile_games"],
    title: "Soccer Mania",
    date: "  September 2021",
    location: null,
    description: "Multiplayer tabletop inspired soccer game",
    detailed_description:
      "This game pits two players against each other in a goal-scoring competition. Players maneuver stationary pins to kick a ball towards the opponent’s goal. The pins are strategically placed over small pits, causing the ball to often end up near another pin when kicked without a clear direction. If the ball becomes unplayable, the player who last controlled the ball has the option to randomly reposition it. The game concludes when a player scores 5 goals. To play the game, two clients are required, which can be opened in separate windows or on different computers.",
    skills: "  Unity 3D, Photon Networking, C#",
    genre: "  Multiplayer sports game",
    role: "  Programming, Networking and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Project developed for Diger Yapim",
    image:
      "https://lh3.googleusercontent.com/bC5ZE4M7-ZNT5H0WkbqgQFTDcoPZlfdo_paIP7zVyq27J-yzVq44UInTeC8LLNR5Ag",
    images: [
      "https://lh3.googleusercontent.com/FhhwLPqpOdJyVDt4hABwySPR80lpj97sJBTXbqXOpuAzj225gsgAu4qHfd9XJAia8w",
      "https://lh3.googleusercontent.com/tD2Y_LAO6BZYTKVLa4Y8Jk8_pBhOyIeAj9_WPjVyMjuidSwKjFeG8uzNXZLOAeYTRGKd",
      "https://lh3.googleusercontent.com/Tymms1MH6fK3z1gaVjM6deBcRHHArgBdfDPffdcGpwnniGkwkfvNby5khlsqr8EZrA",
    ],
    horizontalImages: false,
    webGLUrl: "https://itch.io/embed-upload/7989127?color=333333",
    webgl_width: "850",
    webgl_height: "520",
    github_link: "https://github.com/canafarci/SoccerMania",
    behance_link: null,
  },
  //!Montana
  {
    category: ["other_work"],
    title: "Montana NFT Video Collection",
    date: "  April  2022",
    description:
      "Pseudo-random generation and After Effects video generation of an NFT project",
    detailed_description:
      "My role in the project involved developing pseudo-random generation scripts for Adobe Photoshop. These scripts were designed to merge layers according to specific rarities, as outlined in the design document. Additionally, I was responsible for generating the metadata for the blockchain network.",
    skills: "  Javascript, Python, After Effects",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/43c99f152295771.631bc7ae9f68b.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/43c99f152295771.631bc7ae9f68b.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e834e5152295771.631bc7ae9f15f.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/3fc45b152295771.631bc7aea0651.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/33a8eb152295771.631bc7ae9d74d.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7fe754152295771.631bc7ae9dc8a.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/0f5351152295771.631bc7ae9c29d.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/a14a5c152295771.631bc7ae9ccee.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/c2b08e152295771.631bc7ae9b20f.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/a5c3b6152295771.631bc7aea00fb.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/8a3698152295771.631bc7ae9ec32.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/46c979152295771.631bc7ae9d22c.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/1334ed152295771.631bc7ae9e1b9.jpg",
    ],
    horizontalImages: true,
    videos: [
      "https://www-ccv.adobe.io/v1/player/ccv/NiXq5JREIE2/embed?api_key=behance1&bgcolor=%23191919",
      "https://www-ccv.adobe.io/v1/player/ccv/SfqjNzC7RcL/embed?api_key=behance1&bgcolor=%23191919",
      "https://www-ccv.adobe.io/v1/player/ccv/3CR3fxf2ewW/embed?api_key=behance1&bgcolor=%23191919",
      "https://www-ccv.adobe.io/v1/player/ccv/MH4sZAh-D-F/embed?api_key=behance1&bgcolor=%23191919",
    ],
    webGLUrl: null,
    github_link: "https://github.com/canafarci/MontanaNFTGenerator",
    behance_link:
      "https://www.behance.net/gallery/152295771/Montana-NFT-Collection",
  },
  //!RPG Unity Game
  {
    category: ["pc_games"],
    title: "ARPG Project",
    date: "  March 2020  ",
    description: "Action role playing game prototype",
    detailed_description:
      "I embarked on my journey into Game Development with this project. It features fundamental combat mechanics and an implemented inventory system.",
    skills: "  C#, Unity",
    genre: null,
    job_type: "  Passion project",
    role: "  Gameplay and UI programming and general development",
    image: "https://i.ibb.co/V9Yzpt9/Screenshot-2023-06-08-at-20-47-10.png",
    horizontalImages: true,
    doubleImage: true,
    webGLUrl: "https://itch.io/embed-upload/8072398?color=333333",
    webgl_height: 500,
    webgl_width: 850,
    github_link: "https://github.com/canafarci/ARPG-Repository",
  },
  //!ERG
  {
    category: ["architectural_designs"],
    title: "ERG Headquarters",
    date: "  October 2020",
    location: "   Gölbaşı // Ankara",
    description: "Office Building and headquarters complex",
    detailed_description:
      "Façade, landscape and interior design work with 3D renders for a construction company",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/e2ad75152287153.631b2cef765a6.png",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/e2ad75152287153.631b2cef765a6.png",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/7d9922152287153.631b2cef77914.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/77a3db152287153.631b2cef76f6b.png",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152287153/ERG-Headquarters",
  },
  //!Weapon rush
  {
    category: ["mobile_games"],
    title: "Weapon Rush: Runner Clicker",
    date: "  February 2023",
    location: null,
    description: "Roguelite inspired runner game",
    detailed_description:
      "The objective of this game is to enhance the player’s abilities and acquire followers to defeat the final boss. \nUpon the player’s demise, they are resurrected with all previously purchased upgrades intact. However, these upgrades are reset when the player advances to the subsequent level.",
    skills: "  Unity 3D, C#",
    genre: "  Runner",
    role: "  Programming and Gameplay Design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://play-lh.googleusercontent.com/_DpBIJhbAyLWsSY1lcPQXOB9yRr7o9Yw-LdiUxzaOOPzItvhHOBO0ZR4iy5fdnWbRHk=s256-rw",
    images: [
      "https://play-lh.googleusercontent.com/-6YTic5_e90IA_72oIBv5ivGHob3cH2qzCqgbysgyq4xpHKj6lKrfcPKkwaouxm61w=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/lLN4HWmV98bZ0h0uqkux5kNqg3J-QI-lV4hD4CH0_nvO19QS-Ff_XmdS2wrWmoanuZ0=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/cTPhu8kWPaz8NXnUYSM9U_u_fBQg7KHa0Vkx4f6J3ykQdAvYtkwUhC3s21v3D_TrWA=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/bDNAsuykXaX5IBmy21iaou-4eFblq19ThhvfAtwVMNnr77S7oZGNZYBsuq-ptYKKEFQ=w2560-h1440-rw",
    ],
    horizontalImages: true,
    videoUrl: "https://www.youtube.com/embed/sfCsFO9W1BQ",
    webGLUrl: "https://itch.io/embed-upload/7927013?color=ededed",
    webgl_width: "500",
    webgl_height: "850",
    github_link: "https://github.com/canafarci/RunnerClicker",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.weaponrush",
    behance_link: null,
  },
  //!Interior Study
  {
    category: ["architectural_designs"],
    title: "Interior Study",
    date: "  May 2020",
    location: null,
    description: "Interior design, lighting and render study",
    detailed_description: "",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/08ef64152289001.631b33a54c2ac.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/08ef64152289001.631b33a54c2ac.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152287153/ERG-Headquarters",
  },
  //!Storage Wars
  {
    category: ["mobile_games"],
    title: "Storage Masters 3D",
    date: "  February 2022",
    location: null,
    description: "Storage discovery and sale simulator",
    detailed_description:
      "In this game, the player discovers hidden storages, uncovers valuable items, and auctions them off to accumulate wealth.",
    skills: "  Unity 3D, C#",
    genre: "  Simulator",
    role: "  Programming, Gameplay and Level design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://i.ibb.co/4SK5HKT/uc-export-view-id-1-a-IUOMMz-DZ71-OP09gt-C1-Bcuny-Bn-AZF9x.png",
    images: [
      "https://i.ibb.co/pbZqXTy/uc-export-view-id-17k2u1ya-Pn-MDUeo-YWKP0yq-8-Ku-Ln-Rlq-Yh.jpg",
      "https://i.ibb.co/SXWRbzB/uc-export-view-id-12ir4-Jt-XFXn-Tba-KXj64nk0y2-YDz8h9-L36.jpg",
      "https://i.ibb.co/dJfq4BW/uc-export-view-id-1-KEv-Txu-G-bv3-s3z-N4krjq8-Uv-Ad-SZDSrk.jpg",
      "https://i.ibb.co/2knHg0t/uc-export-view-id-1qses-PNGdsw9-Uvl-Y6-Yuuf-Nh-Ep-Jb-Bt-Hb-RP.jpg",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7937385?color=ededed",
    webgl_width: "500",
    webgl_height: "875",
    github_link: "https://github.com/canafarci/StorageWars",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.storagemasters",
    behance_link: null,
  },
  //!Alya Park
  {
    category: ["architectural_designs"],
    title: "Alya Park Orman",
    date: "  November 2020",
    location: "   Sarıyer // İstanbul",
    description:
      "Façade and landscape design for a residential Complex in İstanbul",
    detailed_description:
      "Façade, landscape and interior design work with 3D renders",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/b77663152279677.631b11b4625a1.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/b77663152279677.631b11b4625a1.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/b6295b152279677.631b11b463796.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/035368152279677.631b11b461b2a.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/41abe4152279677.631b11b462dd3.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/f4144b152279677.631b11b46133c.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152279677/Alya-Park-Saryer",
  },
  //!Easter Egg
  {
    category: ["mobile_games"],
    title: "Easter Egg Runner",
    date: "  April 2022",
    location: null,
    description: "Easter themed forward stack runner",
    detailed_description:
      "In this game, the player assembles a pile of Easter eggs on the screen, adorns them with paint and stencil decorations, all while dodging obstacles. \nThese decorated eggs are then given to a girl who distributes them as gifts to neighboring houses.",
    skills: "  Unity 3D, C#",
    genre: "  Runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "	https://img.itch.zone/aW1nLzEyMjExMDQ3LnBuZw==/315x250%23c/tr2v7f.png",
    images: [
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA3Ny5qcGc=/347x500/cGTg1Q.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA3OC5qcGc=/347x500/MDDi9t.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA4MC5qcGc=/347x500/kHAekF.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA4MS5qcGc=/347x500/Dobxvz.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA4My5qcGc=/347x500/0a70Rg.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA4NS5qcGc=/347x500/36LgXY.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA4NC5qcGc=/347x500/sYbFbr.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA3NjAxMi8xMjIxMTA4Mi5qcGc=/347x500/DxGWrn.jpg",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7939428?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/EasterEggRunner",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.easteregg",
    behance_link: null,
  },
  //!Antep
  {
    category: ["architectural_designs"],
    title: "Municipal Offices Interior",
    date: "  September 2020",
    location: "   Şehitkamil  // Gaziantep",
    description: "Interior design project for a government office complex.",
    detailed_description:
      "interior design and furniture design work with 3D renders",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2eacbb152287443.631b2dfbcb976.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2eacbb152287443.631b2dfbcb976.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/6e60db152287443.631b2dfbcfba2.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/e040d2152287443.631b2dfbcf397.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/eb28c5152287443.631b2dfbcc122.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/391673152287443.631b2dfbcec3c.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/0e9f00152287443.631b2dfbcd990.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/d34d70152287443.631b2dfbcc8c5.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/51a72a152287443.631b2dfbd02f0.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/f89be2152287443.631b2dfbce2bb.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/36019e152287443.631b2dfbcd0cf.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link:
      "https://www.behance.net/gallery/152287443/Gaziantep-Municipal-Water-Board-Offices-Interior",
  },
  //!Wrecking Throw
  {
    category: ["mobile_games"],
    title: "Wrecking Throw",
    date: "  August 2022",
    location: null,
    description: "Olympics themed pixel demolisher",
    detailed_description:
      "In this game, the player assumes the role of an Olympian hammer thrower who demolishes a variety of voxelated objects.\nThe player has the ability to upgrade the Strength, Stamina, and Speed of the hammers they throw.\nAs objects are destroyed, the player accrues resources.",
    skills: "  Unity 3D, C#",
    genre: "  Pixel Demolisher",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "	https://play-lh.googleusercontent.com/g3YvwR3vaSaqxXqKedlPg1cy-rnaWlBhCkZGcxQoq5qXrQG3wt5IPjZcvibahWhnzDw=w240-h480-rw",
    images: [
      "https://play-lh.googleusercontent.com/ZiUjIECOk0RWwg3-4C2zYFKtcPcKhs9dQNFXlxAWx1q5RCYIBaUrL1u65TtRvdVScd5s=w526-h296-rw",
      "https://play-lh.googleusercontent.com/CQo_uh8rSxqCdoyekhfX6KMEEh0qJFcTil8yVeClTp7Zx2PJ6Qhx-0Me6pvN7UoJihM=w526-h296-rw",
      "https://play-lh.googleusercontent.com/T_04MRQhwFIMUWRhqLj-0mWgyeok19UUT2Es2zVvLlPtwjU2wAAVfALJN_2no4Ig6Q=w526-h296-rw",
      "https://play-lh.googleusercontent.com/NS5qZTMnHOaW6P4ArYA0u9DorItLoaxXqkCmhUZ7bv468_Vqdm2xTSLZfrXVCV6udA=w526-h296-rw",
      "https://play-lh.googleusercontent.com/PCAMn5SQtyuV_3W_BdE44onCffIoRAGgv1dQF7ainf4xBVr5_a5LYxY6et517USI7ObA=w526-h296-rw",
      "https://play-lh.googleusercontent.com/zH6yOHo1GeFCaGi5u5pDWumm2_EOYbaOt-kQ2ASdlPdYefpjThM6928-fe10WefkwHzD=w526-h296-rw",
      "https://play-lh.googleusercontent.com/WmodsvO5bwdotEn4jkUto6m_ePQG6MLVdMm9CBBP4D9W5G-DVHE2O7__WpuzGMIe5as=w526-h296-rw",
      "https://play-lh.googleusercontent.com/RO6P-gismZcWo1kRuTtQHFiDAXTfkDEsspbSCSm74K6w8IO8bVu5qKX6DmFZ0DeGm4U=w526-h296-rw",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7942955?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/WreckingThrow",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.wreckingthrow",
    behance_link: null,
  },
  //!Devrek
  {
    category: ["architectural_designs"],
    title: "Community Housing",
    date: "  November  2021",
    location: "   Devrek  // Zonguldak",
    description:
      "Façade and landscape design work for a community residential complex",
    detailed_description: "",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/07717c152288179.631b30bf43ea9.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/07717c152288179.631b30bf43ea9.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/0a85e9152288179.631b30bf4361f.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/57720b152288179.631b30bf420d2.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link:
      "https://www.behance.net/gallery/152288179/Devrek-Community-Housing",
  },
  //!Güzeloba
  {
    category: ["architectural_designs"],
    title: "Güzeloba Housing",
    date: "  April  2021",
    location: "   Güzeloba  // Antalya",
    description: "Façade and landscape design work for a residential complex",
    detailed_description: "",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/100734152288675.631b32731f6d9.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/100734152288675.631b32731f6d9.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/c393e0152288675.631b32732060a.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c81064152288675.631b32731eed1.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/b8e8ba152288675.631b32731fe6f.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/f03029152288675.631b32731e436.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152288675/Guezeloba-Housing",
  },
  //!Surfer Evolution
  {
    category: ["mobile_games"],
    title: "Level Up Surfers",
    date: "  June 2022",
    location: null,
    description: "Evolution runner with a beach theme",
    detailed_description:
      "In this game, the player navigates a naval vehicle through a sea race, skillfully avoiding obstacles to accumulate points.\nAs the player levels up, they gain access to faster naval vehicles, increasing their chances of winning the race.",
    skills: "  Unity 3D, C#",
    genre: "  Evolution Runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "	https://play-lh.googleusercontent.com/cGACGBmP9nxmMT_60BzuJKAXjSvWeQRfuiA41Kdr51j4CBDQ7KZ1O6Cz7HRBFC2Bu7g=w240-h480-rw",
    images: [
      "https://play-lh.googleusercontent.com/c80R1XU1FI-w9a2Nne1aYW0PzPPfO1Rs7CQP5KQlD3uGwToS2Iv8pXWRh71An9JDpg=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/saPLxWJlMaY3YCqCsS34Ax9n7wb1hBn-Ix6Y-Cpw8nKPF6BZSSlQpJyp_IUiEeI5vhQ=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/VTzPHq_IudwuHVXSFf2pWlHYfASCVYS41J15W0LTVuMv7YRre613lnab91gA_RWs7g=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/xEY445hGtkP05yFRex1wjpSAmLY6h_PSynA1R8k-PY5TMkb0iQIj1jhlB1_58v2pV9sk=w2560-h1440-rw",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7962575?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/LevelupSurfers",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.levelupsurfers",
    behance_link: null,
  },
  //!Başiskele
  {
    category: ["architectural_designs"],
    title: "Başiskele Hotel",
    date: "  January  2021",
    location: "   Başiskele  // Kocaeli",
    description: "Façade and landscape design work for a hotel complex",
    detailed_description: "",
    skills: "  3DS Max, Corona Renderer, Substance Painter, Photoshop, AutoCAD",
    genre: null,
    job_type: "  Freelance Project",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/1c8f9b152284167.631b221faef62.jpg",
    images: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/9e666f152284167.631b221fae490.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/319b3a152284167.631b221fb0c9d.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/2242f5152284167.631b221fafa3d.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/794949152284167.631b221fb032e.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/1c8f9b152284167.631b221faef62.jpg",
    ],
    videoUrl: null,
    webGLUrl: null,
    github_link: null,
    behance_link: "https://www.behance.net/gallery/152288675/Guezeloba-Housing",
  },
  //!Gear and Ropes
  {
    category: ["mobile_games"],
    title: "Gear and Ropes",
    date: "  June 2022",
    location: null,
    description: "Mechanical themed puzzle",
    detailed_description:
      "The player endeavors to illuminate a lamp by strategically connecting gear and rope pieces.",
    skills: "  Unity 3D, C#",
    genre: "  Puzzle",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "	https://play-lh.googleusercontent.com/q_YRzVyGo12M_FYnhS429m5Mc5frweeE80s5YMFOKgWYsdwRfcx2cVuCbBSZ9bMzlVfn=w240-h480-rw",
    images: [
      "https://play-lh.googleusercontent.com/lo0qyR-kRDPvyCSs3F0jrQ3rfrnD1GIL5WUw5Lduly_1XWaKd7wOoJL4rhS-x9XyuGA7=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/txGwUpwPFNQ7MuwACpi23HFGtfwVdNaJrbsXxBsZ9CQrx0aLrYb-ZHOGZ9A2PA-Itmk=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/NfkDCdSRPptw2eBCFgQg2LdSSp5vDSBKe3tx8olPptYT7ljhrDd0wIpPm9ymuIehFf0=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/INu9tWnlUKyYsEy3GzEZKs5V1fHDZX_GYrcEWptAYHEHI9OjRPhxntgg9w1-FYRxHbSN=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/oeA559DPs5DMbf_HHeoL9khR6EEx6rhDe9oni7WB9z3_nd4KTMF47LM8-TtmQSQWE_vv=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/3290gRU0E5v08lEof_7K8dZ4IhI3-om17sQtJh0IGcYgA0W3wqn9m9-gWy7vSxikEsI=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/tmgfnr-kr_eNPpWXsX2td3P8NwRCtJbRYZCvL0pXkrmuzuYdQeidaSR5wwnsC7QaJw=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/hGO9esJlRENJa2OfhaCYKn8x2Af5UEqIDML1yVr8EmK2JWGmo_0JdyH6RBDxYJfXHA=w2560-h1440-rw",
    ],
    horizontalImages: true,
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/GearAndRopes",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.gearswitch",
    behance_link: null,
  },
  //!Deadly motoracer
  {
    category: ["mobile_games"],
    title: "Deadly Motoracer",
    date: "  July 2022",
    location: null,
    description: "Motorcycle highway speeding simulator",
    detailed_description:
      "In this game, the player skillfully maneuvers through traffic, aiming to remain within a specified area to gain speed.",
    skills: "  Unity 3D, C#",
    genre: "  Simulator",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "	https://play-lh.googleusercontent.com/XXMBHsk2U2iY_VeQFZ3NiTmCu-pOZngNgcWoEdrnP8_X5ESlfPNd_FQTqXF6xIQaj5k=w240-h480-rw",
    images: [
      "https://play-lh.googleusercontent.com/1FP85PT3sd8dQWpAj_qGb38lctAT5Cgg_w7SoAVrU8gqtjCCNf13lJF18u1F-gnvHJy5=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/wvx2JSVU6A80EFRqHsMou9emYlPE7-nypeIfgTTzHku5uO3e8hQoZBzQ_w32ix3CzQw=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/dv9Nn9qw2FJX2rxmn0SQSARGD2rC9u4pkcNiW1Wnw1tJqz6D0dVC_ZZGztiY17j38Xs=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/TTHDx002-1yMUaQYIvm8ce-NxPudYTKE2S4Oyd4HRT_CJWT1pRnQQxl0bZMve0nmifdi=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/XycvmS3ONgaip1WJkqNCJ63Ufj0TzP05_aqrGaOSwZoTTVNbejx0oQEWhYlVgckJ7TU=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/89-TJMkpD2swi8hU4E-eotPoJskFHfnv8_A8-v9a5JSdV02EBLbWI-bJt5LkiNAnHt4=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/ASeKdFCABWA2vw2ioyyqgDRYdvwsmueQ84DPgmBQGA5Xbr-8fFFRUbSlK72knv0SRkA=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/cpjXnqW8Kifey5IwHdiYIKGYKPG0oMoOyq68gHal3yQA4GP1MSMneu7cBlSpOoh_0g=w2560-h1440-rw",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7962885?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/DeadlyMotoracer",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.deadlymotoracer",
    behance_link: null,
  },
  //!NFT game
  {
    category: ["mobile_games"],
    title: "Non Fungible Game",
    date: "  February 2022",
    location: null,
    description: "NFT crafting and trading simulator",
    detailed_description:
      "In this game, the player constructs a 2D NFT by dragging and dropping various 2D components that form the final image.\nThe player has the ability to color the NFT to their liking.\nOnce the NFT is complete, it can be traded for profit.",
    skills: "  Unity 3D, C#",
    genre: "  Simulator",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://lh3.googleusercontent.com/X1OttOZj76tAZMpOvqd4MSlxpc9Y1K4xJc6o9bx66cknF8pmzpz125cFfGLIZSOLb4oe",
    images: [
      "https://play-lh.googleusercontent.com/po5S3HNCs6t16XGKesBwFXLftQEETfkX34bpZj90VLGcJeSDd1j5vbPjqzLp3aXCCQ=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/bgV0MjceiGWmWxBj7O0qujB13_34A2DrN8r_-fppATr_mFROnqkewpCBjLD2yDI8Wv4a=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/3ICfqczq3lGpq_KXxVB-zXRcLmvZIMYMZukAEIRMTIWr3gbZRjCBkoIDFednLg8ZjEo=w2560-h1440-rw",
      "https://play-lh.googleusercontent.com/G_3AK3iz5rABdIgJ5EQ1zy6gDNGgfHVSYGvQ1UVFOTXTtuGvwzxRCU8rBlH4EKw0HXY=w2560-h1440-rw",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7964347?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/NonFungibleGame",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.nftcrafter",
    behance_link: null,
  },
  //!Air Puppet
  {
    category: ["mobile_games"],
    title: "Air Puppet Rush",
    date: "  January 2022",
    location: null,
    description: "Vertical runner with a air puppet theme",
    detailed_description:
      "The player controls an inflatable mascot that gains height as it progresses through the level. The player must avoid obstacles that cause the mascot to lose height. Additionally, the player has the opportunity to paint and customize their puppet to match a design displayed on the right side of the screen. Upon reaching the end of the level, the player crashes through glass and sticks to a game board to achieve a high score.",
    skills: "  Unity 3D, C#",
    genre: "  Vertical paint runner",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Prototype project developed for Game Theory Games",
    image:
      "https://lh3.googleusercontent.com/O0gCGczCFgJJ7pR7ZZAi-Qnk0W_Qd-UQZ4qYuzlfc7hlRezBv98a9eH-pa_oUOaqw_fV",
    images: [
      "https://lh3.googleusercontent.com/AqcN30eqeYpfswel3n_LeLOh1cE194VVemt2YX7O9yjQkBeJ5lOuecCxA10VdNoBuTY",
      "https://lh3.googleusercontent.com/JGPhFqmAEkfqndvNRH0sgEbdN6cV8bfAMggQktVu4DtioOnmhazo9Nb-exLBstMq-30",
      "https://lh3.googleusercontent.com/r6zpzps3TEKsi8GQ7ovPZ7JSqDDco9tKy1I-5pQTiCKyFkwwBfCkE1dxG1eM8ITkMPQ",
      "https://lh3.googleusercontent.com/JbtDbt2msGvv0yy8XLMEPjd66Ol4SzMDkXO6oMDdTttLTmwr22bLiNGZRtknucd9ZA",
      "https://lh3.googleusercontent.com/g5xaib-mL-o-gQ5VBXEN0jLBndKqmTerL7gnztZiHOZIr4uDfUg0uQfZdmKbkc86SyE",
      "https://lh3.googleusercontent.com/feYMEmvvWwmppQVTlMIAKAIKrdB-7uJK6xBiY0qPbTVTExTINuH2JQwant0GFw2WDxA",
      "https://lh3.googleusercontent.com/WHRWHLlfJQdaOWbjGX9DeYGFVIOFbXDnJUACWDJZ8ItyVfnMaqwny6w6PijzKUS7pHI",
      "https://lh3.googleusercontent.com/_Cdf0yN-bwrf1PXc3zx7g0hW9vozGMojZ5NNt6ZQ1ijyZMERSydr_K0mxXyZcZH1zw",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/7969090?color=ededed",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/AirPuppet",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.flowtart.airpuppetrush",
    behance_link: null,
  },
  //!Rubix Box
  {
    category: ["mobile_games"],
    title: "Rubix Box",
    date: "  May 2021",
    location: null,
    description: "Puzzle game inspired by Rubik's Cube",
    detailed_description:
      "The player is presented with a 5x5 board, with one square left empty to allow for the movement of other pieces. The objective is to arrange the central 3x3 area to match the 3x3 pattern displayed above the game area.",
    skills: "  Unity 3D, C#",
    genre: "  Multiplayer sports game",
    role: "  Programming and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Project developed for Diger Yapim",
    image:
      "https://lh3.googleusercontent.com/66QAX4sh2J0ydwExHp6AACMeAOtX0jsnV-jmM9HtUKwBsyaJrSyNDNZLw-wuo28P820",
    images: [
      "https://img.itch.zone/aW1hZ2UvMjA5Mjc2Ny8xMjMxMTc5MC5qcGc=/794x1000/Zi7HUq.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA5Mjc2Ny8xMjMxMTc5Mi5qcGc=/794x1000/ahibCW.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA5Mjc2Ny8xMjMxMTc5MS5qcGc=/794x1000/hwZJN3.jpg",
      "https://img.itch.zone/aW1hZ2UvMjA5Mjc2Ny8xMjMxMTc5My5qcGc=/794x1000/mAf6Pr.jpg",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/8006590?color=333333",
    webgl_width: "500",
    webgl_height: "870",
    github_link: "https://github.com/canafarci/RubixBox",
    behance_link: null,
  },
  //!Arch Date prediction
  {
    category: ["academic_work"],
    title: "Architectural Date Prediction AI",
    skills: "  Tensorflow, Python",
    date: "  2020",
    location: "   Ankara // Turkey",
    description:
      "Multiclass classification AI for predicting the construction date of an building. ",
    detailed_description:
      "This initiative employs TensorFlow and generative AI methodologies to forecast the indoor lighting conditions of a conceptual plan sketch by examining color-coded architectural diagrams associated with daylighting simulation outcomes. \n\nBefore joining the EU-sponsored research program on global warming, which concentrated on urban heat islands and eventually became my thesis subject, I devised this AI model as a potential thesis theme.",
    genre: null,
    job_type: "  Study Collection",
    image:
      "https://i.ibb.co/Qn1Fhy0/uc-export-view-id-13-NAIhe-XVOXo-Yf-EEJPutdg-VRMm-W0-Erws.jpg",
    pdf_link:
      "https://drive.google.com/file/d/1yHt_2Mw_kAkW725McSCc1BRioEyrmKgv/preview",
  },
  //!Slick Gammon
  {
    category: ["mobile_games"],
    title: "Slick Gammon",
    date: "  May 2021",
    location: null,
    description: "Multiplayer arcade game",
    detailed_description:
      "Two players engage in a competition to stack all the pucks on their opponent’s side by propelling them across with a slingshot. The game offers two modes: one for competing against an AI, and another for local multiplayer. Please note, to enjoy the offline multiplayer feature, a device with touch input is necessary as mouse clicks are not registered in this build. Consider downloading the app from the Play Store for a better experience.",
    skills: "  Unity 3D, C#",
    genre: "  Multiplayer arcade game",
    role: "  Programming, AI and Gameplay design, completed with the Flowtart Studios team",
    job_type: "  Project developed for Diger Yapim",
    image:
      "https://play-lh.googleusercontent.com/MDDKo1f2o4bEgD-MYAEEto7mJoZe0jgmze4RYzzbKqZi7EhHqqfhEnD_TMkQPNH3fQ=w480-h960",
    images: [
      "https://img.itch.zone/aW1hZ2UvMjA5MzIwNC8xMjMxNDM5Mi5wbmc=/794x1000/T30Yo5.png",
      "https://img.itch.zone/aW1hZ2UvMjA5MzIwNC8xMjMxNDM5NS5wbmc=/794x1000/NlClNx.png",
      "https://img.itch.zone/aW1hZ2UvMjA5MzIwNC8xMjMxNDQwMi5wbmc=/794x1000/fDO8EI.png",
      "https://img.itch.zone/aW1hZ2UvMjA5MzIwNC8xMjMxNDQwMS5wbmc=/794x1000/b2L9XP.png",
    ],
    horizontalImages: true,
    webGLUrl: "https://itch.io/embed-upload/8008499?color=333333",
    webgl_width: "500",
    webgl_height: "870",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.digeryapim.slickgammon",
    behance_link: null,
  },
  //!port website
  {
    category: ["other_work"],
    title: "Portfolio Website",
    date: "  June  2022",
    description: "A website for showcasing my work collection",
    detailed_description: "This website was developed by me using React",
    skills: "  Javascript, React, Photoshop",
    genre: null,
    job_type: "  Personal Project",
    image: "https://i.ibb.co/jbYPDd5/Screenshot-2023-06-09-at-01-30-23.png",
    github_link: "https://github.com/canafarci/portfolio-website",
  },
  //!Expense Tracker
  {
    category: ["other_work"],
    title: "Expense Tracker",
    date: "   June 2022",
    location: "   Ankara // Turkey",
    description: "Expense tracking app built in React Native",
    detailed_description:
      "This is a mobile application developed as a freelance project. The app leverages Firebase for user authentication via email and password login, and employs a real-time database to store recent expenses. Users have the ability to view expenses recorded in the past month, as well as edit and delete them. Additionally, an option is provided to check the full expense history.",
    skills: "  Javascript, React Native, Expo, Google Firebase, REST API",
    genre: null,
    job_type: "  Freelance project",
    image: "https://i.ibb.co/QJ6pLWB/1-1.jpg",
    images: [
      "https://i.ibb.co/QJ6pLWB/1-1.jpg",
      "https://i.ibb.co/xjpbbvp/uc-export-view-id-1x9-Spu-Vr-Jn-J4mtl-VPd1-UP097-RV-3-Ju-QT.jpg",
      "https://i.ibb.co/DQ9GnBY/uc-export-view-id-1-k-KNCGk-IMx-Tsd7-UXWTc-S4d-TOB8-Ot-Zzf-L.jpg",
      "https://i.ibb.co/Z6DsCNq/1-3.jpg",
      "https://i.ibb.co/gvVFppj/1-4.jpg",
      "https://i.ibb.co/2ZMxFtD/uc-export-view-id-1-VA9hd-sjw-VZXnlwc-AUhd7-C-h-Idsj-T-ap.jpg",
      "https://i.ibb.co/Z2PGcGk/uc-export-view-id-1v-M3h-Yww-URLnjwc-AYf-Cs-MSk-OFl-Vyu-Njwp.jpg",
      "https://i.ibb.co/gvVFppj/1-4.jpg",
    ],
    horizontalImages: true,
    github_link: "https://github.com/canafarci/ExpenseTracker",
  },
];
